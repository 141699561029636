import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from '@/components/UIComponents/Pagination'
import ValidationSelect from '@/components/UIComponents/Inputs/ValidationSelect'
import ValidationSelectHorizontal from '@/components/UIComponents/Inputs/ValidationSelectHorizontal'
import EmployeeSearch from '@/components/Dashboard/Views/Components/EmployeeSearch'
import ShiftPatternSetting from './ShiftPatternSetting'
import vSelect from 'vue-select'
import mixin from '@/lib/mixin'
import swal from 'sweetalert2'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

export default {
  name: 'DesiredShiftConfirmSearchMixin',
  mixins: [mixin],
  components: {
    PPagination,
    ValidationSelect,
    ValidationSelectHorizontal,
    EmployeeSearch,
    ShiftPatternSetting,
    vSelect
  },
  data () {
    return {
      componentKey: 0,
      draggable: true,
      isChanged: false,
      showSearchCondition: true,
      modalWidth: 800,
      modalWidthSub: 1100,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
        total: 0
      },
      tableData: undefined,
      tableDataOrg: undefined,
      employee_list: undefined,
      adjustment: {
        unset_holiday_flg: false
      },
      searchCond: {
        office_code: '',
        target_year: '',
        target_month: '',
        employee_code: '',
        employee_name: ''
      },
      showOperation: true,
      showPrint: true,
      showEmployee: true,
      labels: {},
      mode: 1,
      params: {},
      comboData: {},
      tableWrapperStyle: '',
      tableStyle: '',
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: [],
      shiftPublishFlg: true,
      beforeShiftPublishFlg: true,
      maxHeight: 0,
      index: 0,
      workScheduleList: [],
      desiredShiftErrors: [],
      showNotify: false,
      detail: {},
      selectedIndex: 0,
      employeeShiftScheduleOut: [],
      messageList: []
    }
  },
  computed: {
    screenCode () {
      return '06-005'
    },
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
    shiftTerm () {
      const shiftTerm = []
      shiftTerm.push(this.labels.office)
      shiftTerm.push('：')
      shiftTerm.push(this.getLabel(this.getSelectName(this.detail.office_code, this.comboData.office_list)))
      shiftTerm.push('　')
      shiftTerm.push(this.$t('label.term'))
      shiftTerm.push('：')
      shiftTerm.push(this.formatDateWith(this.detail.term_from, 'YYYY/M/D(ddd)'))
      shiftTerm.push(' - ')
      shiftTerm.push(this.formatDateWith(this.detail.term_to, 'YYYY/M/D(ddd)'))
      return shiftTerm.join('')
    },
    isModified () {
      return JSON.stringify(this.tableData) !== JSON.stringify(this.tableDataOrg)
    }
  },
  methods: {
    addRow () {
      const shiftScheduleList = []
      for (const day of this.days) {
        shiftScheduleList.push({
          day: day,
          confirm_job_start: '',
          confirm_job_end: '',
          confirm_work_status: false,
          confirm_work_status_legal: false,
          work_schedule_code: '',
          desired_job_start: '',
          desired_job_end: '',
          desired_work_status: 1,
          shift_reflection_flg: 5,
          shift_reflection_class: 'no-hover',
          isWorkScheduleEditable: false,
          is_input: true
        })
      }
      const newRow = {
        working_days: 0,
        working_minutes: 0,
        desired_working_days: 0,
        desired_working_minutes: 0,
        selected: false,
        added: true,
        work_schedule_list: [{label: '', value: ''}],
        shift_schedule_list: shiftScheduleList
      }
      this.calcWorkingMinutes(newRow)
      this.tableData = [newRow, ...this.tableData]
    },
    confirmWorkStatusChanged (day) {
      if (day.confirm_work_status) {
        day.confirm_work_status_legal = false
      }
      this.tableData = [...this.tableData]
    },
    confirmWorkStatusLegalChanged (day) {
      if (day.confirm_work_status_legal) {
        day.confirm_work_status = false
      }
      this.tableData = [...this.tableData]
    },
    editWorkSchedule (row, dayIndex) {
      const shiftScheduleList = [...row.shift_schedule_list]
      shiftScheduleList[dayIndex].isWorkScheduleEditable = true
      this.tableData = this.tableData.map((rowData) => rowData.employee_code === row.employee_code ? {...row, shift_schedule_list: shiftScheduleList} : rowData)
    },
    applyWorkSchedule (row, dayIndex) {
      const workSchedule = this.comboData.work_schedule_list.filter((element) => element.value === row.shift_schedule_list[dayIndex].work_schedule_code)[0]
      if (row.shift_schedule_list[dayIndex].confirm_work_status || row.shift_schedule_list[dayIndex].confirm_work_status_legal) {
        row.shift_schedule_list[dayIndex].confirm_job_start = ''
        row.shift_schedule_list[dayIndex].confirm_job_end = ''
      } else {
        row.shift_schedule_list[dayIndex].confirm_job_start = workSchedule.job_start || ''
        row.shift_schedule_list[dayIndex].confirm_job_end = workSchedule.job_end || ''
      }
      this.calcWorkingMinutes(row)
    },
    showShiftPatternSetting (index, row) {
      this.index = this.from + index
      this.workScheduleList = row.work_schedule_list
      this.$modal.show('shiftPatternSetting')
    },
    hideShiftPatternSetting (detail) {
      this.$modal.hide('shiftPatternSetting')
      if (detail.changed) {
        this.applyShiftPattern(detail)
      }
    },
    applyShiftPattern (detail) {
      const index = this.index
      const pattern = this.comboData.shift_pattern_list.filter((element) => element.value === detail.shift_pattern_code)[0]
      if (!this.tableData[index].work_schedule_list.find((workSchedule) => workSchedule && workSchedule.value === pattern.work_schedule_code_sun)) {
        this.tableData[index].work_schedule_list.push(this.comboData.work_schedule_list.find((workSchedule) => workSchedule.value === pattern.work_schedule_code_sun))
      }
      if (!this.tableData[index].work_schedule_list.find((workSchedule) => workSchedule && workSchedule.value === pattern.work_schedule_code_mon)) {
        this.tableData[index].work_schedule_list.push(this.comboData.work_schedule_list.find((workSchedule) => workSchedule.value === pattern.work_schedule_code_mon))
      }
      if (!this.tableData[index].work_schedule_list.find((workSchedule) => workSchedule && workSchedule.value === pattern.work_schedule_code_tue)) {
        this.tableData[index].work_schedule_list.push(this.comboData.work_schedule_list.find((workSchedule) => workSchedule.value === pattern.work_schedule_code_tue))
      }
      if (!this.tableData[index].work_schedule_list.find((workSchedule) => workSchedule && workSchedule.value === pattern.work_schedule_code_wed)) {
        this.tableData[index].work_schedule_list.push(this.comboData.work_schedule_list.find((workSchedule) => workSchedule.value === pattern.work_schedule_code_wed))
      }
      if (!this.tableData[index].work_schedule_list.find((workSchedule) => workSchedule && workSchedule.value === pattern.work_schedule_code_thu)) {
        this.tableData[index].work_schedule_list.push(this.comboData.work_schedule_list.find((workSchedule) => workSchedule.value === pattern.work_schedule_code_thu))
      }
      if (!this.tableData[index].work_schedule_list.find((workSchedule) => workSchedule && workSchedule.value === pattern.work_schedule_code_fri)) {
        this.tableData[index].work_schedule_list.push(this.comboData.work_schedule_list.find((workSchedule) => workSchedule.value === pattern.work_schedule_code_fri))
      }
      if (!this.tableData[index].work_schedule_list.find((workSchedule) => workSchedule && workSchedule.value === pattern.work_schedule_code_sat)) {
        this.tableData[index].work_schedule_list.push(this.comboData.work_schedule_list.find((workSchedule) => workSchedule.value === pattern.work_schedule_code_sat))
      }
      for (const row of this.tableData[index].shift_schedule_list) {
        const day = parseInt(row.target_date.split('/')[2], 10)
        if (day < detail.from_date || detail.to_date < day) continue
        if (row.confirm_shift_schedule.length > 0 && (row.confirm_shift_schedule[0].shift_reflection_flg === 3 || row.confirm_shift_schedule[0].shift_reflection_flg === 4)) continue
        if (!row.is_input) continue
        const weekday = this.formatDateWith(row.target_date, 'd')
        switch (weekday) {
          case '0':
            if (pattern.work_status_sun === 2 || pattern.work_status_sun === 3) {
              row.work_schedule_code = pattern.work_schedule_code_sun
              row.confirm_job_start = ''
              row.confirm_job_end = ''
              row.confirm_work_status = pattern.work_status_sun === 2
              row.confirm_work_status_legal = pattern.work_status_sun === 3
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            } else if (pattern.job_start_sun) {
              row.work_schedule_code = pattern.work_schedule_code_sun
              row.confirm_job_start = pattern.job_start_sun
              row.confirm_job_end = pattern.job_end_sun
              row.confirm_work_status = false
              row.confirm_work_status_legal = false
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            }
            break
          case '1':
            if (pattern.work_status_mon === 2 || pattern.work_status_mon === 3) {
              row.work_schedule_code = pattern.work_schedule_code_mon
              row.confirm_job_start = ''
              row.confirm_job_end = ''
              row.confirm_work_status = pattern.work_status_mon === 2
              row.confirm_work_status_legal = pattern.work_status_mon === 3
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            } else if (pattern.job_start_mon) {
              row.work_schedule_code = pattern.work_schedule_code_mon
              row.confirm_job_start = pattern.job_start_mon
              row.confirm_job_end = pattern.job_end_mon
              row.confirm_work_status = false
              row.confirm_work_status_legal = false
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            }
            break
          case '2':
            if (pattern.work_status_tue === 2 || pattern.work_status_tue === 3) {
              row.work_schedule_code = pattern.work_schedule_code_tue
              row.confirm_job_start = ''
              row.confirm_job_end = ''
              row.confirm_work_status = pattern.work_status_tue === 2
              row.confirm_work_status_legal = pattern.work_status_tue === 3
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            } else if (pattern.job_start_tue) {
              row.work_schedule_code = pattern.work_schedule_code_tue
              row.confirm_job_start = pattern.job_start_tue
              row.confirm_job_end = pattern.job_end_tue
              row.confirm_work_status = false
              row.confirm_work_status_legal = false
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            }
            break
          case '3':
            if (pattern.work_status_wed === 2 || pattern.work_status_wed === 3) {
              row.work_schedule_code = pattern.work_schedule_code_wed
              row.confirm_job_start = ''
              row.confirm_job_end = ''
              row.confirm_work_status = pattern.work_status_wed === 2
              row.confirm_work_status_legal = pattern.work_status_wed === 3
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            } else if (pattern.job_start_wed) {
              row.work_schedule_code = pattern.work_schedule_code_wed
              row.confirm_job_start = pattern.job_start_wed
              row.confirm_job_end = pattern.job_end_wed
              row.confirm_work_status = false
              row.confirm_work_status_legal = false
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            }
            break
          case '4':
            if (pattern.work_status_thu === 2 || pattern.work_status_thu === 3) {
              row.work_schedule_code = pattern.work_schedule_code_thu
              row.confirm_job_start = ''
              row.confirm_job_end = ''
              row.confirm_work_status = pattern.work_status_thu === 2
              row.confirm_work_status_legal = pattern.work_status_thu === 3
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            } else if (pattern.job_start_thu) {
              row.work_schedule_code = pattern.work_schedule_code_thu
              row.confirm_job_start = pattern.job_start_thu
              row.confirm_job_end = pattern.job_end_thu
              row.confirm_work_status = false
              row.confirm_work_status_legal = false
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            }
            break
          case '5':
            if (pattern.work_status_fri === 2 || pattern.work_status_fri === 3) {
              row.work_schedule_code = pattern.work_schedule_code_fri
              row.confirm_job_start = ''
              row.confirm_job_end = ''
              row.confirm_work_status = pattern.work_status_fri === 2
              row.confirm_work_status_legal = pattern.work_status_fri === 3
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            } else if (pattern.job_start_fri) {
              row.work_schedule_code = pattern.work_schedule_code_fri
              row.confirm_job_start = pattern.job_start_fri
              row.confirm_job_end = pattern.job_end_fri
              row.confirm_work_status = false
              row.confirm_work_status_legal = false
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            }
            break
          case '6':
            if (pattern.work_status_sat === 2 || pattern.work_status_sat === 3) {
              row.work_schedule_code = pattern.work_schedule_code_sat
              row.confirm_job_start = ''
              row.confirm_job_end = ''
              row.confirm_work_status = pattern.work_status_sat === 2
              row.confirm_work_status_legal = pattern.work_status_sat === 3
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            } else if (pattern.job_start_sat) {
              row.work_schedule_code = pattern.work_schedule_code_sat
              row.confirm_job_start = pattern.job_start_sat
              row.confirm_job_end = pattern.job_end_sat
              row.confirm_work_status = false
              row.confirm_work_status_legal = false
              row.shift_reflection_flg = parseInt(detail.shift_reflection_flg, 10)
              row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            }
            break
        }
      }
      this.calcWorkingMinutes(this.tableData[index])
      this.tableData = this.tableData.map((row) => ({...row, work_schedule_list: [...row.work_schedule_list], shift_schedule_list: [...row.shift_schedule_list]}))
    },
    // イベントを処理する場合はカスタマイズエリアに実装してください。
    // 初期処理カスタマイズ用
    afterInit (res) {
      let today = new Date()
      // 事業所に初期値を設定
      this.searchCond.office_code = res.common_area.combo_data.office_list[0].value
      // 期間に来月を設定
      if (today.getMonth() !== 11) {
        this.searchCond.target_year = today.getFullYear()
        this.searchCond.target_month = today.getMonth() + 2
      // 12月の場合、翌年の1月を設定
      } else {
        this.searchCond.target_year = today.getFullYear() + 1
        this.searchCond.target_month = 1
      }
      this.maxHeight = window.innerHeight - 480
      // アラートから遷移
      if (this.$route.params.attendanceRecord) {
        this.searchCond.office_code = this.$route.params.attendanceRecord.office_code
        this.searchCond.target_year = this.$route.params.attendanceRecord.target_year
        this.searchCond.target_month = this.$route.params.attendanceRecord.target_month
        this.search()
      }
    },
    // 検索ボタン押下処理カスタマイズ用
    afterSearch (res) {
      this.isChanged = false
      this.beforeShiftPublishFlg = this.shiftPublishFlg = res.api_area.shift_publish_flg !== 2
      this.employeeShiftScheduleOut = res.api_area.employee_shift_schedule_out
      this.days = this.tableData.length > 0 ? this.tableData[0].shift_schedule_list.map((element) => element.target_date) : res.api_area.employee_shift_schedule_out[0].shift_schedule_list.map((element) => element.target_date)
      this.comboData.shift_pattern_list = res.common_area.combo_data.shift_pattern_list.map((element) => ({...element, label: element.shift_pattern_name, value: element.shift_pattern_code}))
      this.comboData.work_schedule_list = res.common_area.combo_data.work_schedule_list.filter((element) => !element.value.includes('_SHIFT_'))
      this.comboData.work_schedule_list.push({label: this.$t('label.shift_holiday'), value: 'holiday'})
      this.comboData.work_schedule_list.unshift({label: '', value: ''})
      this.employee_list = res.api_area.employee_list
      this.detail.office_code = this.searchCond.office_code
      this.detail.term_from = String(this.searchCond.target_year) + '/' + ('0' + String(this.searchCond.target_month)).slice(-2) + '/' + ('0' + String(this.days[0])).slice(-2)
      this.detail.term_to = String(this.searchCond.target_year) + '/' + ('0' + String(this.searchCond.target_month)).slice(-2) + '/' + ('0' + String(this.days[this.days.length - 1])).slice(-2)
      for (const employee of this.tableData) {
        employee.added = employee.office_code !== this.searchCond.office_code
        employee.selected = false
        employee.working_days = 0
        employee.working_minutes = 0
        employee.desired_working_days = 0
        employee.desired_working_minutes = 0
        employee.work_schedule_list = employee.work_schedule_list.filter((element) => !element.value.includes('_SHIFT_'))
        employee.work_schedule_list.unshift({label: '', value: ''})
        for (const row of employee.shift_schedule_list) {
          row.confirm_job_start = row.confirm_shift_schedule.length === 0 ? '' : row.confirm_shift_schedule[0].job_start
          row.confirm_job_end = row.confirm_shift_schedule.length === 0 ? '' : row.confirm_shift_schedule[0].job_end
          row.confirm_work_status = row.confirm_shift_schedule.length === 0 ? false : row.confirm_shift_schedule[0].work_status === 2
          row.confirm_work_status_legal = row.confirm_shift_schedule.length === 0 ? false : row.confirm_shift_schedule[0].work_status === 3
          row.work_schedule_code = row.confirm_shift_schedule.length === 0 || row.confirm_shift_schedule[0].work_schedule_code.includes('_SHIFT_') ? '' : row.confirm_shift_schedule[0].work_schedule_code
          row.desired_job_start = row.desired_shift_schedule.length === 0 ? '' : row.desired_shift_schedule[0].job_start
          row.desired_job_end = row.desired_shift_schedule.length === 0 ? '' : row.desired_shift_schedule[0].job_end
          row.desired_work_status = row.desired_shift_schedule.length === 0 ? 1 : row.desired_shift_schedule[0].work_status
          row.shift_reflection_flg = row.confirm_shift_schedule.length === 0 ? row.desired_shift_schedule.length === 0 ? 5 : 1 : row.confirm_shift_schedule[0].shift_reflection_flg
          row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
          row.isWorkScheduleEditable = false
        }
        this.calcWorkingMinutes(employee)
      }
      this.tableDataOrg = JSON.parse(JSON.stringify(this.tableData))
    },
    // 労働時間再計算
    calcWorkingMinutes (employee) {
      employee.working_days = 0
      employee.working_minutes = 0
      employee.desired_working_days = 0
      employee.desired_working_minutes = 0
      for (const row of employee.shift_schedule_list) {
        if (row.desired_job_start) {
          employee.desired_working_days += 1
          employee.desired_working_minutes += this.calcTimeToHour(row.desired_job_start, row.desired_job_end, []) * 60
        }
        if (row.confirm_working_status === 2) continue
        if (row.shift_reflection_flg === 4 && row.confirm_shift_schedule.length === 1 && row.confirm_shift_schedule[0].real_total_minutes) {
          employee.working_days += 1
          employee.working_minutes += row.confirm_shift_schedule[0].real_total_minutes
        } else if (row.confirm_job_start) {
          employee.working_days += 1
          const workingHours = this.calcTimeToHour(row.confirm_job_start, row.confirm_job_end, [])
          const breakMinutes = row.confirm_shift_schedule.length === 0 || !row.confirm_shift_schedule[0].break_minutes ? 0 : row.confirm_shift_schedule[0].break_minutes
          employee.working_minutes += workingHours * 60 - breakMinutes
        }
      }
      this.tableData = [...this.tableData]
    },
    // 送信データ調整用
    convertSearchCond (searchCond) {
      // String変換、0埋め
      searchCond.target_year = String(searchCond.target_year)
      searchCond.target_month = ('00' + searchCond.target_month).slice(-2)
      // 不要要素削除
      delete searchCond.employee_name
      return searchCond
    },
    convertEmployeeSearchCond (searchCond) {
      const employeeSearchCond = {
        search_type: '1'
      }
      return employeeSearchCond
    },
    officeCodeChanged () {},
    doFix () {
      swal({
        text: this.$t('message.shift_fix_confirmation_title'),
        type: 'info',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no'),
        confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
        buttonsStyling: false,
        allowOutsideClick: false,
        width: '600px'
      }).then(() => {
        this.doUpdate(true)
      })
    },
    customValidate () {
      this.desiredShiftErrors = []
      for (const employee of this.tableData) {
        if (employee.selected) {
          continue
        }
        if (!employee.employee_code) {
          this.desiredShiftErrors.push(this.$t('message.shift_employee_not_found'))
          continue
        }
        for (const row of employee.shift_schedule_list) {
          if (!this.isTime(row.confirm_job_start)) {
            this.desiredShiftErrors.push(employee.employee_name + ': ' + this.$t('message.invalid_start_time_at').replace('{date}', row.target_date))
          }
          if (!this.isTime(row.confirm_job_end)) {
            this.desiredShiftErrors.push(employee.employee_name + ': ' + this.$t('message.invalid_end_time_at').replace('{date}', row.target_date))
          }
          if (this.isTime(row.confirm_job_start) && this.isTime(row.confirm_job_end)) {
            if (!!row.confirm_job_start !== !!row.confirm_job_end) {
              this.desiredShiftErrors.push(employee.employee_name + ': ' + this.$t('message.inconsistent_start_end_time').replace('{date}', row.target_date))
            }
            if (row.confirm_job_start && row.confirm_job_end) {
              if (this.compareTime(row.confirm_job_start, row.confirm_job_end) > 0) {
                this.desiredShiftErrors.push(employee.employee_name + ': ' + this.$t('message.inconsistent_time_at').replace('{date}', row.target_date))
              }
            }
          }
        }
      }
      return this.desiredShiftErrors.length === 0
    },
    doUpdate (confirmFlg) {
      let isCustomValid = this.customValidate()
      if (!isCustomValid) {
        console.log(this.desiredShiftErrors)
        this.desiredShiftErrors = [...this.desiredShiftErrors]
        this.$emit('on-submit', this.registrationForm, false)
        return
      }
      let isEmployeeExist = false
      for (const employee of this.tableData) {
        if (employee.selected) continue
        if (employee.office_code !== this.detail.office_code) {
          let isShiftExist = false
          for (const row of employee.shift_schedule_list) {
            if (row.job_start || row.job_end || row.work_schedule_code) {
              isShiftExist = true
              break
            }
          }
          if (!isShiftExist) {
            isEmployeeExist = true
            break
          }
        }
      }
      if (isEmployeeExist) {
        swal({
          html: `${this.$t('message.no_shift_employee_title')}<br/><small>${this.$t('message.no_shift_employee_content')}</small>`,
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false,
          width: '600px'
        }).then(() => {
          this.update(confirmFlg)
        })
        return
      }
      this.update(confirmFlg)
    },
    update (confirmFlg) {
      const shiftData = []
      for (const employee of this.tableData) {
        if (employee.selected) continue
        const employeeData = {
          employee_code: employee.employee_code,
          employee_shift_schedules: []
        }
        for (const row of employee.shift_schedule_list) {
          const data = {
            target_date: row.target_date,
            work_status: row.confirm_work_status ? 2 : row.confirm_work_status_legal ? 3 : 1,
            job_start: this.formatTime(row.confirm_job_start),
            job_end: this.formatTime(row.confirm_job_end),
            work_schedule_code: row.work_schedule_code === 'holiday' ? '_SHIFT_000' : row.work_schedule_code,
            shift_reflection_flg: row.shift_reflection_flg === 5 ? 1 : row.shift_reflection_flg
          }
          if (row.confirm_shift_schedule.length === 1) {
            if (data.work_status !== row.confirm_shift_schedule[0].work_status ||
              data.job_start !== row.confirm_shift_schedule[0].job_start ||
              data.job_end !== row.confirm_shift_schedule[0].job_end ||
              data.work_schedule_code !== row.confirm_shift_schedule[0].work_schedule_code
            ) {
              data.shift_reflection_flg = 1
            }
          }
          employeeData.employee_shift_schedules.push(data)
        }
        shiftData.push(employeeData)
      }
      const apiArea = {
        target_month: String(this.searchCond.target_year) + ('0' + String(this.searchCond.target_month)).slice(-2),
        office_code: this.searchCond.office_code,
        confirm_flg: confirmFlg,
        shift_datas: shiftData
      }
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: apiArea
      }
      this.message = {}
      this.showNotify = false
      this.send('/update/desired_shift_confirm/list', reqData)
      .then(res => {
        if (res.message.message_classification === 1) {
          this.showNotify = true
          this.updateResultByResponse(res)
          // this.isChanged = true
          // this.showSearchCondition = true
          let reqData = {
            screen_code: this.screenCode,
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: this.convertSearchCond({...this.searchCond})
          }
          delete reqData.api_area.employee_code
          const url = `${process.env.VUE_APP_API_ORIGIN}/view/shift_schedule_adjustment/list`
          const axiosWithCookies = this.$axios.create({
            withCredentials: url.indexOf(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN) === -1
          })
          axiosWithCookies.post(url, reqData)
          .then(response => {
            this.adjustment = response.data.root.api_area
            console.log(this.adjustment)
          })    
        }
      }).catch(error => {
        console.error(error)
      })
    },
    canUpdate (row) {
      return true
    },
    canDelete (row) {
      return true
    },
    doClose () {
      this.$emit('close', this.isChanged)
    },
    doBack () {
      this.$router.go(-1)
    },
    toggleSearchCondition () {
      this.showSearchCondition = !this.showSearchCondition
    },
    searchClicked (newSearchCond) {
      this.searchCond = newSearchCond
      this.searchCond.search_type = parseInt(newSearchCond.search_type, 10)
      this.search()
    },
    search () {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: this.convertSearchCond({...this.searchCond})
      }
      this.send('/view/desired_shift_confirm/list', reqData)
      .then(res => {
        this.showNotify = false
        this.updateResultByResponse(res)
      })
      delete reqData.api_area.employee_code
      const url = `${process.env.VUE_APP_API_ORIGIN}/view/shift_schedule_adjustment/list`
      const axiosWithCookies = this.$axios.create({
        withCredentials: url.indexOf(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN) === -1
      })
      axiosWithCookies.post(url, reqData)
      .then(response => {
        this.adjustment = response.data.root.api_area
        console.log(this.adjustment)
      })
    },
    updateResultByResponse (res) {
      this.pagination.currentPage = 1
      this.tableData = Array.isArray(res.api_area.employee_shift_schedule_in) ? res.api_area.employee_shift_schedule_in : [res.api_area.employee_shift_schedule_in]
      let labels = this.getLabels(res.common_area)
      this.comboData = this.getComboData(this.comboData, res.common_area.combo_data)
      this.labels = {...this.labels, ...labels}
      this.labels.update_employee_ryaku = this.$t('label.update_employee_ryaku')
      for (const row of this.tableData) {
        for (const entry of Object.entries(row)) {
          if (entry[1] === null) {
            row[entry[0]] = ''
          }
        }
      }
      this.afterSearch(res)
      if (this.tableData.length > 0 && this.showSearchCondition) {
        this.toggleSearchCondition()
      }
      this.$nextTick(() => {
        let width = 0
        if (!this.$refs.table) return
        for (const child of this.$refs.table.$children) {
          if (child.width) {
            width += parseInt(child.width, 10)
          }
        }
        this.tableStyle = 'width: ' + (width + 1) + 'px'
        const maxWidth = window.innerWidth - 360
        if (width > maxWidth) {
          width = maxWidth
          this.tableWrapperStyle = 'width: ' + maxWidth + 'px; overflow-x: auto;'
        } else {
          this.tableWrapperStyle = ''
        }
        // this.$refs.pager.style.width = width + 'px'
        // this.$refs.summary.style.width = width + 'px'
        this.componentKey += 1
      })
    },
    init () {
      let data = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          employee_code: this.params.employee_code
        }
      }
      this.send('/init/desired_shift_confirm/list', data)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
        this.comboData = this.getComboData(res.common_area.combo_data)
        this.afterInit(res)
      })
    },
    handleView (index, row) {
      this.params = {}
      this.params.wage_ledger_code = row.wage_ledger_code
      this.mode = this.modes.view
      this.$modal.show('send')
    },
    handleEdit (index, row) {
      this.params = {}
      this.params.wage_ledger_code = row.wage_ledger_code
      this.mode = this.modes.edit
      this.$modal.show('send')
    },
    handleDelete (index, row) {
      this.params = {}
      this.params.wage_ledger_code = row.wage_ledger_code
      this.mode = this.modes.delete
      this.$modal.show('send')
    },
    showAdd () {
      this.params = {}
      this.mode = this.modes.add
      this.$modal.show('send')
    },
    hide (...args) {
      this.$modal.hide('send')
    },
    tableRowClassName ({row}) {
      if (row.outdate_flag || row.is_future) {
        return 'out-row'
      } else if (row.update_date) {
        if (this.formatDate(row.update_date.replace('/', '-')) === this.formatToday()) {
          return 'update-row'
        }
      }
      return ''
    },
    tableCellClassName ({row, column, rowIndex, columnIndex}) {
      if (columnIndex >= 5) {
        return `${row.shift_schedule_list[columnIndex - 5].shift_reflection_class}`
      }
      return 'no-hover'
    },
    setImage () {
      const self = this
      for (const row of this.tableData) {
        if (row.picture_uri) {
          this.$firebase.storage()
            .ref()
            .child(row.picture_uri)
            .getDownloadURL()
            .then(function (url) {
              row.imageUrl = url
              self.componentKey += 1
            })
        }
      }
    },
    replaceDate (date) {
      return date.replaceAll('-', '/').substring(5)
    },
    getJobTime (employee, rowEmployeeList) {
      let jobTime = ''
      for (const rowEmployee of rowEmployeeList) {
        if (rowEmployee.employee_code === employee.employee_code) {
          if (rowEmployee.work_status === 2) {
            jobTime = jobTime + '休み'
            break
          }
          jobTime = jobTime + rowEmployee.job_start + '~' + rowEmployee.job_end
          break
        }
      }
      return jobTime
    },
    showEmployeeSearch (index) {
      this.selectedIndex = index
      this.$modal.show('employeeSearch')
    },
    hideEmployeeSearch (selected) {
      if (selected) {
        const outFilteredList = this.employeeShiftScheduleOut.filter((row) => row.employee_code === selected.employee_code)
        const inFilteredList = this.tableData.filter((row) => row.employee_code === selected.employee_code)
        if (outFilteredList.length === 0 || inFilteredList.length > 0) {
          swal({
            text: this.$t('message.cannot_add_shift_employee'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
        } else {
          // モーダルemployeeで従業員が選ばれた場合、従業員番号と従業員名を検索条件に設定
          this.tableData[this.selectedIndex] = outFilteredList[0]
          this.tableData[this.selectedIndex].added = true
          this.tableData[this.selectedIndex].selected = false
          this.tableData[this.selectedIndex].working_days = 0
          this.tableData[this.selectedIndex].working_minutes = 0
          this.tableData[this.selectedIndex].desired_working_days = 0
          this.tableData[this.selectedIndex].desired_working_minutes = 0
          this.tableData[this.selectedIndex].work_schedule_list = this.tableData[this.selectedIndex].work_schedule_list.filter((element) => !element.value.includes('_SHIFT_'))
          this.tableData[this.selectedIndex].work_schedule_list.unshift({label: '', value: ''})
          for (const row of this.tableData[this.selectedIndex].shift_schedule_list) {
            row.confirm_job_start = row.confirm_shift_schedule.length === 0 ? '' : row.confirm_shift_schedule[0].job_start
            row.confirm_job_end = row.confirm_shift_schedule.length === 0 ? '' : row.confirm_shift_schedule[0].job_end
            row.confirm_work_status = row.confirm_shift_schedule.length === 0 ? false : row.confirm_shift_schedule[0].work_status === 2
            row.confirm_work_status_legal = row.confirm_shift_schedule.length === 0 ? false : row.confirm_shift_schedule[0].work_status === 3
            row.work_schedule_code = row.confirm_shift_schedule.length === 0 || row.confirm_shift_schedule[0].work_schedule_code.includes('_SHIFT_') ? '' : row.confirm_shift_schedule[0].work_schedule_code
            row.desired_job_start = row.desired_shift_schedule.length === 0 ? '' : row.desired_shift_schedule[0].job_start
            row.desired_job_end = row.desired_shift_schedule.length === 0 ? '' : row.desired_shift_schedule[0].job_end
            row.desired_work_status = row.desired_shift_schedule.length === 0 ? 1 : row.desired_shift_schedule[0].work_status
            row.shift_reflection_flg = row.confirm_shift_schedule.length === 0 ? row.desired_shift_schedule.length === 0 ? 5 : 1 : row.confirm_shift_schedule[0].shift_reflection_flg
            row.shift_reflection_class = row.shift_reflection_flg === 1 ? 'before-adjustment' : row.shift_reflection_flg === 3 ? 'in-adjustment' : 'no-hover'
            row.isWorkScheduleEditable = false
          }
          this.tableData = [...this.tableData]
        }
      }
      this.$modal.hide('employeeSearch')
    },
    // 従業員番号入力
    employeeSelected () {
      this.searchCond.employee_name = ''
      if (!this.canShow('/SS/menuSSScreen018')) {
        swal({
          title: this.$t('message.employee_not_found'),
          html: this.$t('message.employee_not_found_description'),
          type: 'error',
          confirmButtonClass: 'btn btn-warning btn-sm btn-default',
          buttonsStyling: false,
          allowOutsideClick: false
        }).then(() => {
          this.searchCond.employee_code = ''
          this.searchCond.employee_name = ''
        })
        return
      }
      if (!this.searchCond.employee_code) {
        return
      }
      const searchCond = {
        screen_code: 'SS-018',
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          search_type: 3,
          office_code: '',
          group_code: '',
          team_code: '',
          employee_code: this.searchCond.employee_code,
          employee_name: '',
          employee_classification_code: '',
          retirement: false,
          all_flag: false
        }
      }
      this.tempHelp = this.help
      this.message.text_bk = this.message.text
      this.send('/view/search_employee/list', searchCond)
      .then(res => {
        if (res.api_area.employee_list && res.api_area.employee_list.length > 0) {
          this.searchCond.employee_code = res.api_area.employee_list[0].employee_code
          this.searchCond.employee_name = res.api_area.employee_list[0].employee_name
        } else {
          swal({
            title: this.$t('message.employee_not_found'),
            html: this.$t('message.employee_not_found_description'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          }).then(() => {
            this.searchCond.employee_code = ''
            this.searchCond.employee_name = ''
          })
        }
        this.help = this.tempHelp
        this.message.text = this.message.text_bk
      })
    },
    // 独自バリデーション用
    customValidate (shiftPublishFlg) {
      let isSuccess = true
      // メッセージ初期化
      this.messageList = []
      // PKチェック用
      let pkList = []
      // 公開フラグ
      let isPublic = shiftPublishFlg === 1
      // 公開チェック用
      let publicDateList = []
      // シフト有無判定用
      let isShiftExist = false
      for (let i = 0; i < this.adjustment.shift_schedule_adjustment_list.length; i++) {
        const data = this.adjustment.shift_schedule_adjustment_list[i]
        // 2段目の場合、次の要素を処理
        if (i % 2 === 1) {
          continue
        }
        // メッセージ初期化
        data.business_type_error = ''
        data.work_schedule_code_error = ''
        // 削除がtrueの場合、次の要素を処理
        if (data.delete_check) {
          continue
        }
        // 職種は必須
        if (data.business_type === '') {
          data.business_type_error = this.$t('message.field_required_simple')
          isSuccess = false
        }
        // 勤務体系は必須
        if (data.work_schedule_code === '') {
          data.work_schedule_code_error = this.$t('message.field_required_simple')
          isSuccess = false
        }
        // PK(職種+勤務体系)を設定
        if (data.business_type !== '' && data.work_schedule_code !== '') {
          pkList.push(data.business_type + '_' + data.work_schedule_code)
        }
        // 公開の場合、確定済以外が存在する場合エラー
        if (isPublic) {
          // 1カ月分の情報をループ
          for (const dailyDetail of data.daily_details) {
            // 1日分の従業員をループ
            for (const employee of dailyDetail.employee_list) {
              // 従業員コードがブランクの場合、次の要素を処理
              if (employee.employee_code === '') {
                continue
              }
              isShiftExist = true
              // ステータスがブランク以外が存在する場合、エラー対象日を設定
              if (employee.status !== '') {
                publicDateList.push(dailyDetail.date)
                break
              }
            }
          }
        }
      }
      // PKListと重複削除したPKListの件数が一致しない場合、エラー
      if (pkList.length !== new Set(pkList).size) {
        this.messageList.push(this.$t('message.shift_pk_check'))
        isSuccess = false
      }
      // publicDateListが0件以外の場合、エラー
      if (publicDateList.length !== 0) {
        let item = ''
        for (const publicDate of publicDateList) {
          if (item === '') {
            item = this.formatDateWith(publicDate, 'M/D(ddd)')
          } else {
            item += '、 ' + this.formatDateWith(publicDate, 'M/D(ddd)')
          }
        }
        this.messageList.push(this.$t('message.is_public').replace('{item}', item))
        isSuccess = false
      }
      // シフトが存在しない場合エラー
      if (isPublic && !isShiftExist) {
        this.messageList.push(this.$t('message.shift_is_not_found'))
        isSuccess = false
      }

      return isSuccess
    },
    doPublish (shiftPublishFlg) {
      let isCustomValid = this.customValidate(shiftPublishFlg)
      if (!isCustomValid) {
        this.$emit('on-submit', this.registrationForm, false)
        this.componentKey += 1
        return
      }
      if (shiftPublishFlg === 1) {
        swal({
          title: this.$t('message.shift_publish_confirmation_title'),
          html: this.$t('message.shift_publish_confirmation_html'),
          type: 'info',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no'),
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          buttonsStyling: false,
          allowOutsideClick: false,
          width: '600px'
        }).then(() => {
          this.publish(shiftPublishFlg)
        })
      } else {
        this.publish(shiftPublishFlg)
      }
    },
    publish (shiftPublishFlg) {
      let reqData = {
        screen_code: this.screenCode,
        company_code: this.$store.state.loginUser.companyCode,
        group_code: this.$store.state.loginUser.groupCode,
        employee_code: this.$store.state.loginUser.employeeCode,
        api_area: {
          shift_publish_flg: shiftPublishFlg,
          target_year: this.adjustment.target_year,
          target_month: this.adjustment.target_month,
          office_code: this.adjustment.office_code,
          update_count: this.adjustment.update_count,
          unset_holiday_flg: this.adjustment.unset_holiday_flg,
          shift_schedule_adjustment_list: this.adjustment.shift_schedule_adjustment_list
            .filter((row) => !row.adjustment_for_added_line_flag)
            .map((row) => ({...row, adjustment_for_added_line_flag: undefined})),
        }
      }
      this.message = {}
      this.showNotify = false
      this.send('/update/shift_schedule_adjustment/list', reqData)
      .then(res => {
        this.showNotify = true
        this.shiftPublishFlg = shiftPublishFlg !== 2
        this.adjustment = res.api_area
      }).catch(error => {
        console.error(error)
      })
    }
  },
  created () {
    this.init()
    const currentYear = new Date().getFullYear()
    for (let year = currentYear + 1; year >= 2009; year--) {
      this.years.push(String(year))
    }
  }
}
