<template>
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <modal name="dashboardList" :draggable="true" :clickToClose="false" height="auto" :width="500">
            <div class="modal-body">
              <DashboardList @close="hideDashboardList"/>
            </div>
          </modal>
        </div>
        <div class="col-md-12">
          <modal name="languageSelect" :draggable="true" :clickToClose="false" height="auto" :width="400">
            <div class="modal-body">
              <LanguageSelect @close="hideLanguageSelect"/>
            </div>
          </modal>
        </div>
      </div>
      <div class="navbar-minimize">
        <button class="btn btn-sm btn-default btn-icon" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'ti-shift-right' : 'ti-shift-left'"></i>
        </button>
      </div>
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" :class="{toggled: $sidebar.showSidebar}" @click="toggleSidebar">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar bar1"></span>
          <span class="icon-bar bar2"></span>
          <span class="icon-bar bar3"></span>
        </button>
        <div class="navbar-brand" :title="pageName" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 450px;">
          {{ pageName }}
        </div>
      </div>
      <a class="navbar-brand" style="font-size: x-small;">{{ pageCode }}</a>
      <div class="collapse navbar-collapse">
        <form class="navbar-form navbar-left navbar-search-form" role="search">
          {{$t('label.screen_search')}}&nbsp;&nbsp;
          <div class="input-group">
            <input type="text" v-model="searchKey" class="form-control" placeholder="Search..." @keypress.prevent.enter.exact="searchScreens">
            <a href="#" @click.prevent="searchScreens" style="display: table-cell; padding: 6px 12px; vertical-align: middle; color: #555;"><i class="fa fa-search"></i></a>
          </div>
          <a href="#" @click.prevent.stop="deputyApproverlClicked" v-if="$store.state.deputyApproverlList.length >0" style="color: #666; text-decoration: underline; padding: 0;">{{ deputyApproverlDescription }}</a>
          <span v-if="isTest" style="background-color: #C33; color: white; padding: 2px 10px; margin: 0 20px;">＠テスト</span>
          <span v-if="!isProd && !isTest" style="background-color: #080; color: white; padding: 2px 10px; margin: 0 20px;">＠開発</span>
        </form>
        <ul class="nav navbar-nav navbar-right">
          <li>
            <div style="margin: 25px 0;" v-show="$store.state.noticeList.length > 0">
              <a href="#" @click.prevent="goNotice" style="color: #C33;">{{$t('label.notice_message').replace('{count}', $store.state.noticeList.length)}}</a>&nbsp;&nbsp;
            </div>
          </li>
          <li>
            <div style="margin: 25px 0;">
              {{$t('label.group')}}&nbsp;&nbsp;
            </div>
          </li>
          <li>
            <div class="width300" style="margin: 16px 0;">
              <el-select class="select-success width300"
                          size="large"
                          placeholder=""
                          v-model="group"
                          @change="groupChanged">
                <el-option v-for="group in groups"
                            class="select-success"
                            :value="group.group_code"
                            :label="group.group_name"
                            :key="group.group_code">
                </el-option>
              </el-select>
            </div>
          </li>
          <drop-down tag="li" :title="String(alertList.length)" icon="ti-bell">
            <div style="max-height: calc(100vh - 200px); overflow-y: auto; padding: 10px 0;">
              <li style="padding: 5px;" v-if="alertList.length === 0">
                &nbsp;&nbsp;&nbsp;&nbsp;{{ $t('label.no_alert') }}
              </li>
              <li style="padding: 5px;" v-if="alertList.length > 0">
                <input type="checkbox" v-model="allSelect" class="gray" @click.stop="selectAll"/>
                <button type="button" class="btn btn-sm btn-default btn-submit" @click.stop="deleteAlert">
                  <span class="btn-label"><i class="fa fa-check"></i></span>
                  {{$t('button.delete')}}
                </button>
              </li>
              <li v-for="alert in alertList" v-bind:key="alert.aleat_number">
                <div style="width: 380px;">
                  <input type="checkbox" v-model="alert.selected" class="gray" @click.stop=""/>
                  <template v-if="alert.screen_code">
                    <a class="alert-row" @click="showWorkflow(alert)">{{alert.notification}}</a>
                  </template>
                  <template v-else>
                    {{alert.notification}}
                  </template>
                </div>
              </li>
            </div>
          </drop-down>
          <drop-down tag="li" icon="ti-settings">
            <!-- <li><a @click="showLanguageSelect">{{$t('label.change_language')}}</a></li> -->
            <li><a @click="showChangePassword">{{$t('label.change_password')}}</a></li>
            <li><a href="https://linkpoint.zendesk.com/hc/ja/" target="_blank">{{$t('label.help')}}</a></li>
          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  import swal from 'sweetalert2'
  import mixin from '@/lib/mixin'
  import DashboardList from '@/components/Dashboard/Views/Dashboard/DashboardList'
  import LanguageSelect from '@/components/Dashboard/Views/Dashboard/LanguageSelect'

  export default {
    mixins: [mixin],
    components: {
      DashboardList,
      LanguageSelect
    },
    data () {
      return {
        allSelect: false,
        searchKey: '',
        applicationFormCode: '',
        selectedRow: {},
        activeNotifications: false,
        group: '001',
        groups: [
          { 'group_name': 'アプリケーション開発部', 'group_code': '001' },
          { 'group_name': '総務部', 'group_code': '002' }
        ]
      }
    },
    methods: {
      selectAll () {
        for (const alert of this.alertList) {
          alert.selected = !this.allSelect
        }
      },
      deputyApproverlClicked () {
        this.$router.push({name: 'proxyApprovalSetting'})
      },
      searchScreens () {
        this.$router.push({name: 'generatedSearchScreen2', params: {search_key: this.searchKey}})
        .catch(() => {
          this.$router.push({name: 'generatedSearchScreen', params: {search_key: this.searchKey}})
        })
      },
      groupChanged () {
        let data = {
          screen_code: '00-000',
          company_code: this.$store.state.loginUser.companyCode,
          group_code: this.group,
          employee_code: this.$store.state.loginUser.employeeCode
        }
        this.send('/view/menu/list', data)
        .then(res => {
          const sidebarLinks = this.getSidebarLinks(this, 2,
            res.api_area.menu_list, res.api_area.employee_dashboard_list, this.$store.state.dbList)
          const loginInfo = this.$store.state.loginUser
          loginInfo.sidebarLinks = sidebarLinks
          loginInfo.groupCode = this.group
          for (const row of this.groups) {
            if (row.group_code === this.group) {
              loginInfo.groupName = row.group_name
              break
            }
          }
          this.$store.commit('save', loginInfo)
          this.$router.push({name: 'dashboardFrame'})
        })
      },
      showChangePassword () {
        this.$router.push({name: 'changePassword'})
      },
      showDashboardList () {
        this.$modal.show('dashboardList')
      },
      hideDashboardList (reloadRequired) {
        this.$modal.hide('dashboardList')
        if (reloadRequired && window.currentUrl === '/DB/menuDBScreen001') {
          window.location.reload()
        }
      },
      showLanguageSelect () {
        this.$modal.show('languageSelect')
      },
      hideLanguageSelect (reloadRequired) {
        this.$modal.hide('languageSelect')
        if (reloadRequired) {
          window.location.reload()
        }
      },
      goNotice () {
        this.$router.push({name: 'noticeSearch'})
      },
      showWorkflow (row) {
        const params = {}
        params.attendanceRecord = {}
        params.attendanceRecord.employee_code = this.$store.state.loginUser.employeeCode
        if (row.parameter) {
          for (const entry of row.parameter.split('&')) {
            const keyValuePair = entry.split('=')
            params.attendanceRecord[keyValuePair[0]] = keyValuePair[1]
          }
        }
        if (row.screen_code === '01-004') {
          this.$router.push({name: 'PaidHolidaysSearch', params: params})
        } else if (row.screen_code === '01-008') {
          this.$router.push({name: 'generatedWorkScheduleAllocationSearch', params: params})
        } else if (row.screen_code === '01-009') {
          this.$router.push({name: 'ApplicationStatusSearch', params: params})
        } else if (row.screen_code === '01-010') {
          this.$router.push({name: 'ApprovalStatusSearch', params: params})
        } else if (row.screen_code === '01-014') {
          this.$router.push({name: 'generatedWorkStatusSearch', params: params})
        } else if (row.screen_code === '01-015') {
          this.$router.push({name: 'generatedPaidPaymentManagementDetailSearch', params: params})
        } else if (row.screen_code === '02-001') {
          this.$router.push({name: 'createPaySlipSearch', params: params})
        } else if (row.screen_code === '03-002') {
          this.$router.push({name: 'generatedOfficeSearch', params: params})
        } else if (row.screen_code === '03-005') {
          this.$router.push({name: 'employee', params: params})
        } else if (row.screen_code === '03-022') {
          this.$router.push({name: 'generatedClosingSearch', params: params})
        } else if (row.screen_code === '04-004') {
          this.$router.push({name: 'workflowAttendanceRecord', params: params})
        } else if (row.screen_code === '04-005') {
          this.$router.push({name: 'workflowStampTime', params: params})
        } else if (row.screen_code === '04-006') {
          this.$router.push({name: 'workflowLateEarlyDepartureTime', params: params})
        } else if (row.screen_code === '04-007') {
          this.$router.push({name: 'workflowOvertime', params: params})
        } else if (row.screen_code === '04-008') {
          this.$router.push({name: 'workflowLateNightOverwork', params: params})
        } else if (row.screen_code === '04-009') {
          this.$router.push({name: 'workflowHolidayWork', params: params})
        } else if (row.screen_code === '04-010') {
          this.$router.push({name: 'workflowTransferHolidayWork', params: params})
        } else if (row.screen_code === '04-011') {
          this.$router.push({name: 'workflowTimeLimitExceeded', params: params})
        } else if (row.screen_code === '04-012') {
          this.$router.push({name: 'workflowLeaveJob', params: params})
        } else if (row.screen_code === '04-013') {
          this.$router.push({name: 'workflowGround', params: params})
        } else if (row.screen_code === '04-014') {
          this.$router.push({name: 'workflowHoliday', params: params})
        } else if (row.screen_code === '06-001') {
          this.$router.push({name: 'closingAttendanceRecord', params: params})
        } else if (row.screen_code === '06-002') {
          this.$router.push({name: 'changeSocialInsuranceSearch', params: params})
        } else if (row.screen_code === '06-005') {
          this.$router.push({name: 'desiredShiftConfirmSearch', params: params})
        } else if (row.screen_code === '06-006') {
          this.$router.push({name: 'shiftDecisionSearch', params: params})
        } else if (row.screen_code === '07-001') {
          this.$router.push({name: 'annualCalendarSearch', params: params})
        } else if (row.screen_code === '07-002') {
          this.$router.push({name: 'agreement36Search', params: params})
        } else if (row.screen_code === '07-010') {
          this.$router.push({name: 'calculationBasisSearch', params: params})
        } else if (row.screen_code === '08-007') {
          this.$router.push({name: 'ledgerReportList', params: params})
        } else if (row.screen_code === '09-011') {
          this.$router.push({name: 'legalRuleSurveillanceSearch', params: params})
        } else if (row.screen_code === '11-005') {
          this.goNotice()
        }
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      },
      deleteAlert () {
        swal({
          text: this.$t('message.confirm_to_delete'),
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          cancelButtonClass: 'btn btn-warning btn-sm btn-default btn-sweet-alert',
          type: 'warning',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('button.yes'),
          cancelButtonText: this.$t('button.no')
        }).then(() => {
          const newAlertList = []
          const deleteList = []
          for (const row of this.alertList) {
            if (row.selected) {
              deleteList.push({
                screen_code: row.screen_code,
                alert_number: row.aleat_number
              })
            } else {
              newAlertList.push(row)
            }
          }
          let reqData = {
            screen_code: '00-000',
            company_code: this.$store.state.loginUser.companyCode,
            group_code: this.$store.state.loginUser.groupCode,
            employee_code: this.$store.state.loginUser.employeeCode,
            api_area: {
              alert_list: deleteList
            }
          }
          this.send(`/delete/alert/list`, reqData)
          .then(res => {
            this.allSelect = false
            this.$store.commit('setAlertList', newAlertList)
          }).catch(error => {
            console.error(error)
          })
        }).catch(() => {})
      },
      getPage () {
        var code = ''
        var name = this.$t('label.unknown_screen')
        let path = this.$route.path
        if (path.startsWith('/11/menu11Screen903')) path = '/11/menu11Screen003'
        for (let menu of this.$store.state.loginUser.sidebarLinks) {
          for (let child of menu.children) {
            if (path.startsWith(child.path)) {
              code = child.code
              name = `${menu.name} > ${child.name}`
            }
          }
        }
        for (let ss of this.$store.state.ssList) {
          if (this.$route.path.startsWith(ss.path)) {
            code = ss.code
            name = ss.name
          }
        }
        if (window.previousUrl === '/01/menu01Screen002/list' && window.currentUrl === '/01/menu01Screen002/detail') {
          name = name + ` > ${this.$t('label.attendance_record')}`
        }
        if (window.currentUrl === '/DB/menuDBScreen012') {
          code = 'DB-012'
          name = this.$t('label.ledger')
        }
        if (window.currentUrl === '/99/confirmation') {
          name = this.$t('label.notice_confirmation')
        }
        if (window.currentUrl === '/DB/menuDBScreen001') {
          return {code: '', name: ''}
        }
        if (this.$route.params.paramRoleCode && window.previousUrl === '/03/menu03Screen015/search' && window.currentUrl === '/03/menu03Screen019/search') {
          const screens = name.split(' > ')
          for (let menu of this.$store.state.loginUser.sidebarLinks) {
            for (let child of menu.children) {
              if ('/03/menu03Screen015'.startsWith(child.path)) {
                code = '03-019'
                name = `${screens[0]} > ${child.name} > ${screens[1]}`
                break
              }
            }
          }
        }
        if (window.currentUrl === '/03/menu03Screen014/lunch') {
          code = '03-014'
          name = name + ` > ${this.$t('label.lunch')}`
        } else if (window.currentUrl === '/03/menu03Screen015/allocation') {
          code = '03-015'
          name = name + ` > ${this.$t('label.assign_to_employee')}`
        }
        return {code: code, name: name}
      }
    },
    computed: {
      pageCode () {
        return this.getPage().code
      },
      pageName () {
        return this.getPage().name
      },
      deputyApproverlDescription () {
        const deputyApproverlList = this.$store.state.deputyApproverlList
        if (deputyApproverlList.length === 1) {
          return this.$t('label.deputy_approverl_description1').replace('{employeeCode}', deputyApproverlList[0].employee_code).replace('{employeeName}', deputyApproverlList[0].employee_name)
        } else {
          return this.$t('label.deputy_approverl_description2').replace('{employeeCode}', deputyApproverlList[0].employee_code).replace('{employeeName}', deputyApproverlList[0].employee_name).replace('{num}', deputyApproverlList.length - 1)
        }
      },
      alertList () {
        return this.$store.state.alertList ? this.$store.state.alertList : []
      }
    },
    created () {
      if (this.$store.state.loginUser.groupList) {
        this.groups = this.$store.state.loginUser.groupList
        this.group = this.$store.state.loginUser.groupCode
      }
    }
  }

</script>
<style scoped>
  .alert-row {
    cursor: none;
    text-decoration: none;
  }
  .alert-row:hover {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
