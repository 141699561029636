<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: #f5f5f5; z-index: 10;" v-if="!initialized"></div>
    <!-- 従業員検索モーダル パラメータ：検索条件 -->
    <modal name="employeeSearch" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="700">
      <div class="modal-body">
        <EmployeeSearch :orgSearchCond="convertEmployeeSearchCond(searchCond)" :is-modal="true" :shift="true" @close="hideEmployeeSearch" />
      </div>
    </modal>
    <!-- シフトパターン設定モーダル パラメータ：ラベル、プルダウンリスト -->
    <modal name="shiftPatternSetting" :draggable="true" :clickToClose="false" height="auto" :scrollable="true" :width="400">
      <div class="modal-body">
        <ShiftPatternSetting :labels="labels" :combo-data="comboData" :work-schedule-list="workScheduleList" :is-modal="true" @close="hideShiftPatternSetting" />
      </div>
    </modal>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
        <Help class="pull-right" :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
      </div>
    </div>
    <div ref="frame">
    <form method="#" action="#">
      <div class="card">
        <div class="card-header search-condition-title" @click="toggleSearchCondition">
          <div style="display: inline-block; width: 49%;">
            <h4 class="card-title">
              {{$t('label.search_condition')}}
            </h4>
          </div>
          <div style="display: inline-block; width: 50%; text-align: right; vertical-align: middle;">
            <template v-if="showSearchCondition">
              <span class="ti-angle-up pull-right"></span>
            </template>
            <template v-else>
              <span class="ti-angle-down pull-right"></span>
            </template>
          </div>
        </div>
        <!-- 検索条件 -->
        <div class="card-content" v-if="showSearchCondition">
          <input type="text" name="dummy" style="display: none;">
          <!-- 事業所 -->
          <ValidationSelect
            v-model="searchCond.office_code"
            item-name="office_code"
            :placeholder="$t('label.select_description')"
            :classes="getSelectLengthClass(comboData.office_list)"
            :item-label="labels.office"
            :options="comboData.office_list"
            frame-class="search-condition-item"
            @change="officeCodeChanged"
          />
          <!-- 期間 -->
          <div class="form-group search-condition-item" style="display: inline-block; vertical-align: top;">
            <label>{{$t('label.term')}}</label>
            <div>
              <vSelect name="from_year"
                        class="style-chooser length5"
                        style="display: inline-block;"
                        :clearable="false"
                        :options="years"
                        v-model="searchCond.target_year">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>&nbsp;{{$t('label.year')}}&nbsp;
              <vSelect name="from_month"
                        class="style-chooser length3"
                        style="display: inline-block;"
                        :clearable="false"
                        :options="months"
                        v-model="searchCond.target_month">
                <div slot="no-options">{{$t('message.no_matching_options')}}</div>
              </vSelect>&nbsp;{{$t('label.month')}}&nbsp;
            </div>
          </div>
          <div class="search-button-area">
            <template v-if="labels.search === 1">
              <button type="button" class="btn btn-search btn-sm btn-default" @click="search">
                {{$t('button.search')}}
              </button>
            </template>
          </div>
        </div>
      </div>
    </form>
    <MessageArea :page-type="pageType.search" :message="message" :placeholders="[pageTitle]" :notification="showNotify" />
    <template v-if="tableData && tableData.length >= 0">
      <div class="card">
        <!-- <div class="card-header search-result-title">
          <h4 class="card-title">
            {{$t('label.search_result')}}
          </h4>
        </div> -->
        <div class="card-content">
          <div style="margin-bottom: 10px;">
            {{ shiftTerm }}
          </div>
          <!-- メッセージ -->
          <div class="error-message-wrapper" v-show="messageList.length !== 0">
            <small class="text-danger">
              <template v-for="message in messageList">
                {{ message }}<br>
              </template>
            </small>
          </div>
          <div class="row" ref="summary">
            <div class="col-sm-6 pagination-info">
              <el-select
                class="select-success length2"
                :disabled="isChanged"
                v-model="pagination.perPage">
                <el-option
                  class="select-success"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
              <button type="button" class="btn btn-sm btn-default btn-primary" style="margin-left: 20px;" :disabled="isChanged || shiftPublishFlg" @click="addRow">{{$t('button.add_row')}}</button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div style="display: inline-block; width: 15px; height: 15px; vertical-align: middle; background-color: #e1f5e1; border: 1px solid #999;"></div>：未調整、
              <div style="display: inline-block; width: 15px; height: 15px; vertical-align: middle; background-color: #e1ebf5; border: 1px solid #999;"></div>：調整中、
              <div style="display: inline-block; width: 15px; height: 15px; vertical-align: middle; border: 1px solid #999;"></div>：確定
            </div>
            <div class="col-sm-6">
            </div>
          </div>
          <div style="margin-top: 10px;">
            <div style="color: #C84513; margin-bottom: 5px;" v-for="error in desiredShiftErrors" v-bind:key="error">
              <small>{{error}}</small>
            </div>
            <!-- 日付分のレコードがある想定 -->
            <el-table :header-cell-style="() => 'border-right: 1px solid #ddd;'"
                      border
                      :data="pagedData"
                      :empty-text="$t('label.no_data')"
                      :style="tableStyle"
                      ref="table"
                      :max-height="maxHeight"
                      :row-class-name="tableRowClassName"
                      :cell-class-name="tableCellClassName"
            >
              <!-- 削除 -->
              <el-table-column width="50" fixed="left" :label="$t('label.delete')" align="center" v-if="!isChanged">
                <template slot-scope="props">
                  <div style="text-align: center;">
                    <input type="checkbox" class="gray" v-model="props.row.selected" v-show="props.row.added">
                  </div>
                </template>
              </el-table-column>
              <!-- 従業員 -->
              <el-table-column width="120" fixed="left" :label="labels.employee_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <template v-if="!props.row.employee_code">
                    <button type="button"
                      class="btn btn-sm btn-default btn-warning"
                      :disabled="isChanged"
                      @click="showEmployeeSearch(props.$index)"
                    >
                      <i class="ti-search"></i>
                    </button>
                  </template>
                  <template v-else>
                    <div style="text-align: left; font-size: medium;">
                      {{ props.row.employee_name }}
                    </div>
                    <small>{{ props.row.employee_code }}</small>
                  </template>
                </template>
              </el-table-column>
              <!-- 職種 -->
              <el-table-column width="180" fixed="left" :label="labels.business_ryaku">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left; font-size: medium;">
                    {{ props.row.business_name }}
                  </div>
                </template>
              </el-table-column>
              <!-- 労働時間 -->
              <el-table-column width="80" fixed="left" :label="$t('label.working_minutes')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: right; font-size: medium;">
                    <div style="line-height: 100%; margin-bottom: 40px;">
                      <small>{{ props.row.desired_working_days === 0 ? '' : `${props.row.desired_working_days}${$t('label.unit_day')}` }}</small>
                      <br>
                      <small>{{ props.row.desired_working_days === 0 ? '' : formatDispMinute(props.row.desired_working_minutes) }}</small>
                    </div>
                    <div style="line-height: 110%;">
                      {{ `${props.row.working_days}${$t('label.unit_day')}` }}
                      <br>
                      {{ formatDispMinute(props.row.working_minutes) }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- パターン -->
              <el-table-column width="80" fixed="left" :label="$t('label.pattern')">
                <template slot="header" slot-scope="scope">
                  <div class="phase-header tableHeader">
                    <div class="phase-header__title">{{scope.column.label}}</div>
                  </div>
                </template>
                <template slot-scope="props">
                  <div style="text-align: left; font-size: medium;">
                    <!-- 選択 -->
                    <div style="margin: 5px 0; text-align: center;">
                      <button type="button" class="btn btn-sm btn-default btn-primary" :disabled="isChanged || shiftPublishFlg" @click="showShiftPatternSetting(props.$index, props.row)">{{$t('button.select')}}</button>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- 日付 -->
              <template v-for="(day, dayIndex) in days">
                <el-table-column width="140" :label="day" :key="`${day}-${dayIndex}`">
                  <template slot="header" slot-scope="scope">
                    <div class="phase-header tableHeader">
                      <div class="phase-header__title">{{ formatDateWith(scope.column.label.replaceAll('/', '-'),'M/D(ddd)') }}</div>
                    </div>
                  </template>
                  <template slot-scope="props">
                    <div style="text-align: left; font-size: medium;">
                      <div style="text-align: left; font-size: medium; margin-left: 2px;">
                        <div>
                          <div style="text-align: right; margin-right: 5px;">
                            <small>
                              {{ props.row.shift_schedule_list[dayIndex].desired_work_status === 2 ? $t('label.shift_holiday') : props.row.shift_schedule_list[dayIndex].desired_job_start ? `${props.row.shift_schedule_list[dayIndex].desired_job_start}-${props.row.shift_schedule_list[dayIndex].desired_job_end}` : '&nbsp;' }}
                            </small>
                          </div>
                        </div>
                        <template v-if="props.row.shift_schedule_list[dayIndex].isWorkScheduleEditable">
                          <ValidationSelectHorizontal
                            v-model="props.row.shift_schedule_list[dayIndex].work_schedule_code"
                            :item-name="props.row.employee_code + props.row.shift_schedule_list[dayIndex].work_schedule_code + dayIndex"
                            :placeholder="$t('label.select_description')"
                            classes="length5"
                            :item-label="labels.work_schedule"
                            :options="props.row.work_schedule_list"
                            :disabled="isChanged || shiftPublishFlg"
                            :title="getLabel(getSelectName(props.row.shift_schedule_list[dayIndex].work_schedule_code, comboData.work_schedule_list))"
                            @change="applyWorkSchedule(props.row, dayIndex)"
                          />
                        </template>
                        <template v-else>
                          <div style="display: table; height: 40px;">
                            <div style="display: table-cell; vertical-align: middle;">
                              <a href="#" @click.prevent="editWorkSchedule(props.row, dayIndex)" style="color: #555;" v-show="!isChanged && !shiftPublishFlg && props.row.shift_schedule_list[dayIndex].is_input"><i class="ti-pencil-alt"></i></a>
                              &nbsp;
                              {{ getLabel(getSelectName(props.row.shift_schedule_list[dayIndex].work_schedule_code, comboData.work_schedule_list)) }}
                            </div>
                          </div>
                        </template>
                        <div>
                          <input 
                            type="checkbox" 
                            v-model="props.row.shift_schedule_list[dayIndex].confirm_work_status" 
                            :class="!props.row.shift_schedule_list[dayIndex].is_input ? 'gray2' : 'gray'" 
                            style="vertical-align: middle;" 
                            :disabled="isChanged || !props.row.shift_schedule_list[dayIndex].is_input || shiftPublishFlg" 
                            :id="`confirm_work_status_${props.row.employee_code}_${dayIndex}_1`"
                            :title="$t('label.shift_holiday')"
                            @change="confirmWorkStatusChanged(props.row.shift_schedule_list[dayIndex])"
                          >
                          <label :for="`confirm_work_status_${props.row.employee_code}_${dayIndex}_1`" style="font-weight: 500">
                            <small>{{  $t('label.shift_job_holiday') }}</small>
                          </label>
                          &nbsp;&nbsp;
                          <input 
                            type="checkbox" 
                            v-model="props.row.shift_schedule_list[dayIndex].confirm_work_status_legal" 
                            :class="!props.row.shift_schedule_list[dayIndex].is_input ? 'gray2' : 'gray'" 
                            style="vertical-align: middle;" 
                            :disabled="isChanged || !props.row.shift_schedule_list[dayIndex].is_input || shiftPublishFlg" 
                            :id="`confirm_work_status_legal_${props.row.employee_code}_${dayIndex}`"
                            :title="$t('label.shift_holiday')"
                            @change="confirmWorkStatusLegalChanged(props.row.shift_schedule_list[dayIndex])"
                          >
                          <label :for="`confirm_work_status_legal_${props.row.employee_code}_${dayIndex}`" style="font-weight: 500">
                            <small>{{  $t('label.shift_legal_holiday') }}</small>
                          </label>
                        </div>
                        <div style="margin: 5px 0 5px 1px;">
                          <input 
                            type="text" 
                            style="padding-right: 0.3em; width:50px; text-align: right; border-style: none none solid none;"
                            v-model="props.row.shift_schedule_list[dayIndex].confirm_job_start"
                            :disabled="isChanged || props.row.shift_schedule_list[dayIndex].confirm_work_status || props.row.shift_schedule_list[dayIndex].confirm_work_status_legal || !props.row.shift_schedule_list[dayIndex].is_input || shiftPublishFlg"
                            @blur="calcWorkingMinutes(props.row)">
                          {{ '-' }}
                          <input 
                            type="text" 
                            style="padding-right: 0.3em; width:50px; text-align: right; border-style: none none solid none;"
                            v-model="props.row.shift_schedule_list[dayIndex].confirm_job_end"
                            :disabled="isChanged || props.row.shift_schedule_list[dayIndex].confirm_work_status || props.row.shift_schedule_list[dayIndex].confirm_work_status_legal || !props.row.shift_schedule_list[dayIndex].is_input || shiftPublishFlg"
                            @blur="calcWorkingMinutes(props.row)">
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
          <div class="row" ref="pager">
            <div class="col-sm-4 pagination-info">
              <p class="category">{{$t('label.display_range').replace('{total}', total).replace('{from}', from + 1).replace('{to}', to)}}</p>
            </div>
            <div class="col-sm-8" style="padding: 0">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
          <template v-if="labels.update === 1 && !isChanged">
            <!-- 更新 -->
            <div style="text-align: left; margin-top: 10px; display: inline-block; width: 70px;">
              <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="shiftPublishFlg || !isModified" v-tooltip.top-center="!shiftPublishFlg ? '' : $t('label.shift_publish_text')" @click="doUpdate(false)">
                <span class="btn-label"><i class="fa fa-check"></i></span>
                {{$t('button.update')}}
              </button>
              <br>
            </div>
            <!-- 確定 -->
            <div style="text-align: left; margin-top: 10px; display: inline-block; width: 70px;">
              <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="shiftPublishFlg" v-tooltip.top-center="!shiftPublishFlg ? '' : $t('label.shift_publish_text')" @click="doFix">
                <span class="btn-label"><i class="fa fa-check"></i></span>
                {{$t('button.fix')}}
              </button>
              <br>
            </div>
            <!-- 公開 -->
            <div style="text-align: left; margin-top: 10px; display: inline-block; width: 75px;" v-if="!shiftPublishFlg">
              <button type="button" class="btn btn-sm btn-default btn-submit" :disabled="isModified" @click="doPublish(1)">
                <span class="btn-label"><i class="fa fa-check"></i></span>
                {{$t('button.public')}}
              </button>
            </div>
            <!-- 非公開 -->
            <div style="text-align: left; margin-top: 10px; display: inline-block; width: 85px;" v-if="shiftPublishFlg">
              <button type="button" class="btn btn-sm btn-default btn-submit" @click="doPublish(2)">
                <span class="btn-label"><i class="fa fa-check"></i></span>
                {{$t('button.private')}}
              </button>
            </div>
            <div style="display: inline-block; width: 270px;">
              <input type="checkbox" v-model="adjustment.unset_holiday_flg" class="gray" id="unsetHolidayFlg" :disabled="shiftPublishFlg"/>
              <label for="unsetHolidayFlg" :class="shiftPublishFlg ? 'grayLabel2' : 'grayLabel'">未設定を所定休日とする</label>
            </div>
          </template>
        </div>
        <div class="row" ref="pager"></div>
      </div>
    </template>
    </div>
  </div>
</template>
<script>
import DesiredShiftConfirmSearchMixin from '@/components/Dashboard/Views/Monthly/DesiredShiftConfirmSearchMixin'
export default {
  name: 'DesiredShiftConfirmSearchMixin',
  mixins: [DesiredShiftConfirmSearchMixin]
}
</script>
<style scoped>
.bule {
  color: #0000ff;
  text-decoration: underline;
}
.red {
  color: #ff0000;
  text-decoration: underline;
}
.black {
  color: black;
  text-decoration: underline;
}
</style>

